function Home() {
    return (
        <>
        <h1> Welcome </h1>
        <p>
            I am Kevin Flores. This site is designed as a basic hub of information of what I'm working and what I'm happy to show off what I've been working. Currently, I am searching for a job in web development. In my offtime, I'm working on game development with other friends as a hobby and creating a website based virtual table top.
        </p>
        </>
    );
}

export default Home;
